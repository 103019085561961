
import Vue from "vue";
import {
  Trip,
  Job,
  JobStatus,
  VehicleExpense,
  tripCategory,
  Vehicle
} from "@/shared/models";
import gmapsInit from "@/services/mapService";
import notification from "@/services/notificationService";
import sharedData from "@/shared/data";

export default Vue.extend({
  name: "TripsRightSidebar",
  props: {
    tripId: String,
    vehicleId: String
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
    },
    tripId: function () {
      if (!this.tripId) {
        this.$store.dispatch("setTripDetail", undefined);
      } else {
        // watching jobId value to update jobDetail on value change
        this.getTripDetail(this.tripId, this.vehicleId);
      }
    }
  },
  data() {
    return {
      isLoading: false,
      JobStatus: JobStatus.NEW,
      filteredJobs: undefined as undefined | Job[],
      filters: {
        jobSearchQuery: undefined as undefined | string
      },
      updateTrip: {
        category: undefined as undefined | tripCategory
      },
      updateExpenseDetail: undefined as undefined | VehicleExpense,
      addExpenseDetail: {
        expenseType: undefined as undefined | string,
        merchant: undefined as undefined | string,
        price: undefined as undefined | number,
        dateTime: undefined as undefined | string,
        recurring: undefined as undefined | boolean,
        taxPercent: undefined as undefined | number,
        location: undefined as undefined | string,
        comment: undefined as undefined | string,
        tripId: undefined as undefined | string
      },
      expenseAttachments: [] as File[],
      pendingUploads: 0,
      addJobDetail: {
        title: undefined as undefined | string,
        description: undefined as undefined | string,
        postcode: undefined as undefined | string,
        startDate: undefined as undefined | string,
        endDate: undefined as undefined | string,
        status: JobStatus.NEW,
        assignee: undefined as undefined | string
      },
      linkJobs: [] as string[],
      uploadInProgress: false,
      addJobToggle: false,
      editTripToggle: false,
      addExpenseToggle: false,
      updateExpenseToggle: false,
      deleteExpenseToggle: false,
      viewExpenseToggle: false
    };
  },
  computed: {
    // computed variables from vuex store
    tripDetail(): undefined | Trip {
      return this.$store.state.tripDetail;
    },
    vehicleDetail(): undefined | Vehicle {
      return this.$store.state.vehicleDetail;
    },
    selectedTenant(): undefined | string {
      return this.$store.state.selectedTenant;
    },
    tripExpenses(): undefined | VehicleExpense[] {
      return this.$store.state.tripExpenses;
    },
    expenseDetail(): undefined | VehicleExpense {
      return this.$store.state.expenseDetail;
    },
    jobs(): undefined | Job[] {
      return this.$store.state.jobs;
    },
    attachmentURLs():
      | undefined
      | { url: string; fileKey: string; fileName: string }[] {
      return this.$store.state.attachmentURLS;
    },
    expenseType() {
      return sharedData.expenseType;
    }
  },
  created() {
    this.initializeView();
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        if (this.vehicleId && this.tripId) {
          this.getTripDetail(this.tripId, this.vehicleId);
        } else {
          this.$store.dispatch("setTripDetail", undefined);
        }
      }
    },
    async attachExpenses(
      vehicleId: string,
      expenseId: string,
      // To store already attached files on Expenses
      currentAttachments = undefined as
        | undefined
        | [{ fileKey: string; url: string }]
    ) {
      this.uploadInProgress = true;
      // Initialize empty arrays to prepare data to be send to server
      const attachmentNames: string[] = [];
      const attachmentKeys: string[] = [];
      if (this.expenseAttachments) {
        // Populating attachments name
        for (let i = 0; i < this.expenseAttachments.length; i++) {
          attachmentNames.push(this.expenseAttachments[i].name);
        }
      }
      // If new attachments are added
      if (attachmentNames.length > 0) {
        // Get Upload URLs
        try {
          await this.$store
            .dispatch("getAttachmentURLs", {
              vehicleId: vehicleId,
              attachmentNames: attachmentNames
            })
            .then(async () => {
              if (this.attachmentURLs && this.expenseAttachments) {
                const attachmentsCount = this.attachmentURLs.length;
                this.pendingUploads = attachmentsCount;
                // Upload attached file to Presigned URL
                let uploadSuccess = true;
                const promises = [];
                for (let i = 0; i < attachmentsCount; i++) {
                  attachmentKeys.push(this.attachmentURLs[i].fileKey);
                  promises.push(
                    // uploading one attachment at a time
                    this.$store.dispatch("uploadAttachment", {
                      attachment: this.expenseAttachments[i],
                      url: this.attachmentURLs[i].url
                    })
                  );
                }
                await Promise.all(promises)
                  .then((res) => {
                    // If all Files Uploaded
                    this.uploadInProgress = false;
                  })
                  .catch((err) => {
                    // Error in uploading any of the file to Presigned URL
                    this.uploadInProgress = false;
                    uploadSuccess = false;
                    this.pendingUploads = 0;
                  });

                if (uploadSuccess) {
                  // If expense has existing attachments
                  if (currentAttachments) {
                    for (let i = 0; i < currentAttachments.length; i++) {
                      // Concatenate existing and new attachments
                      attachmentKeys.push(currentAttachments[i].fileKey);
                    }
                  }
                  // Adding attachment to expense.
                  this.$store
                    .dispatch("addExpenseAttachment", {
                      vehicleId: vehicleId,
                      expenseId: expenseId,
                      attachmentKeys: attachmentKeys
                    })
                    .then(() => {
                      this.uploadInProgress = false;
                    })
                    .catch((err) => {
                      this.uploadInProgress = false;
                      this.pendingUploads = 0;
                    });
                }
              }
            });
        } catch (err) {
          // Error while requesting Presigned URL for attachments
          notification.error(err.data.message);
        }
      }
      // If no new attachments were added then (else)
      else {
        // If some attachments were already attached to the expense
        // or removed from the expense
        if (currentAttachments) {
          for (let i = 0; i < currentAttachments.length; i++) {
            // Concatenate existing attachemnts
            attachmentKeys.push(currentAttachments[i].fileKey);
          }
        }
        this.$store
          // Add attachment keys to the expense
          .dispatch("addExpenseAttachment", {
            vehicleId: vehicleId,
            expenseId: expenseId,
            attachmentKeys: attachmentKeys
          })
          .catch((err) => {
            this.pendingUploads = 0;
          });
      }
    },
    dropSelectedExpenseAttachment(index: number) {
      if (this.expenseAttachments) {
        this.expenseAttachments.splice(index, 1);
      }
    },
    dropCurrentExpenseAttachment(index: number) {
      this.updateExpenseDetail?.attachments?.splice(index, 1);
    },
    async getTripDetail(tripId: string, vehicleId: string) {
      try {
        this.isLoading = true;
        // Get trip detail via vuex store
        await this.$store
          .dispatch("getTripDetail", {
            tripId: tripId,
            vehicleId: vehicleId,
            selectedTenant: this.selectedTenant
          })
          .then(async () => {
            if (this.tripDetail) {
              this.updateTrip.category = this.tripDetail.category;
              await this.$store.dispatch("getVehicleDetail", {
                selectedTenant: this.selectedTenant,
                vehicleId: vehicleId
              });
              this.loadMap();
              //this.getJobs();
              //this.fetchExpenses(tripId, vehicleId);
            }
          });
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        notification.error(err.message);
      }
    },
    filterJobs() {
      //remove jobs already linked with trip
      this.filteredJobs = this.jobs;
      // check if search query
      if (this.filters.jobSearchQuery) {
        this.filteredJobs = this.filteredJobs?.filter((jobs) => {
          return jobs.title
            ?.toLowerCase()
            .includes(this.filters.jobSearchQuery!.toLowerCase());
        });
      }
    },
    async getJobs() {
      await this.$store.dispatch("getJobs").then(() => {
        this.filterJobs();
        this.linkJobs = [];
        if (this.tripDetail) {
          for (let i = 0; i < this.tripDetail.jobs.length; i++)
            this.linkJobs.push(this.tripDetail.jobs[i].id);
        }
      });
    },
    fetchExpenses(tripId: string, vehicleId: string) {
      this.$store.dispatch("getTripExpenses", {
        tripId: tripId,
        vehicleId: vehicleId
      });
    },
    async updateTripDetail(tripId: string, vehicleId: string) {
      // create empty selectedjobs array to populate according to selection
      let selectedJobs: (undefined | Job)[] = [];
      if (this.linkJobs && this.jobs) {
        // if there are jobs to be linked to the trip
        if (this.linkJobs.length > 0) {
          const allJobs: undefined | Job[] = this.jobs;
          // populate selected jobs according to user selection recorded in linkJobs
          selectedJobs = allJobs.filter((job) => {
            if (this.linkJobs) {
              return this.linkJobs.indexOf(job.id) !== -1;
            }
          });
        }
      }
      if (this.tripDetail) {
        // add selected trip category to update
        const updatedCategory = this.updateTrip.category;
        // send updated changes request via vuex
        await this.$store
          .dispatch("updateTripDetail", {
            vehicleId: vehicleId,
            tripId: tripId,
            category: updatedCategory,
            jobs: selectedJobs
          })
          .then(() => {
            // On successful trip update remove modal
            this.editTripToggle = false;
            // Emit event trip-update to trigger fetch trips on Trips.vue view
            // to refresh the list after update.
            this.$emit("trip-update");
          });
      }
    },
    async addJobToTrip() {
      // for creating and linking a job to the trip directly on the go
      if (this.tripDetail && this.addJobDetail) {
        const tripId = this.tripDetail.id;
        const jobDetail = this.addJobDetail;
        // dispatch action with the job detail to be added via vuex
        await this.$store
          .dispatch("addJobToTrip", { addJobDetail: jobDetail, tripId: tripId })
          .then(async () => {
            // on job added hide modal and clear addJobDetail so it can be used
            // again for next job addition
            this.addJobToggle = false;
            this.addJobDetail = {
              title: undefined,
              description: undefined,
              postcode: undefined,
              startDate: undefined,
              endDate: undefined,
              status: JobStatus.NEW,
              assignee: undefined
            };
            await this.getTripDetail(this.tripId, this.vehicleId);
          });
      }
    },
    async loadMap() {
      try {
        if (this.tripDetail) {
          const google: any = await gmapsInit();
          const geocoder = new google.maps.Geocoder();
          const map = new google.maps.Map(
            document.getElementById("map") as HTMLElement,
            {
              center: {
                lat: this.tripDetail.sourceLatitude,
                lng: this.tripDetail.sourceLongitude
              },
              zoom: 11,
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                mapTypeIds: ["roadmap", "terrain"]
              }
            }
          );
          const departureMarker = new google.maps.Marker({
            position: {
              lat: this.tripDetail.sourceLatitude,
              lng: this.tripDetail.sourceLongitude
            },
            map,
            title: "Departure"
          });
          const destinationMarker = new google.maps.Marker({
            position: {
              lat: this.tripDetail.destinationLatitude,
              lng: this.tripDetail.destinationLongitude
            },
            map,
            title: "Destination"
          });
          const path = new google.maps.Polyline({
            path: [
              {
                lat: this.tripDetail.sourceLatitude,
                lng: this.tripDetail.sourceLongitude
              },
              {
                lat: this.tripDetail.destinationLatitude,
                lng: this.tripDetail.destinationLongitude
              }
            ],
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2
          });
          departureMarker.setMap(map);
          destinationMarker.setMap(map);

          // Setting map to display all markers via fitBounds()
          const latlngbounds = new google.maps.LatLngBounds();
          latlngbounds.extend(departureMarker.position);
          latlngbounds.extend(destinationMarker.position);
          map.fitBounds(latlngbounds);

          path.setMap(map);
        }
      } catch (err) {
        notification.error(err.message);
      }
    },
    async getExpense(expenseId: string) {
      await this.$store.dispatch("getExpenseDetail", {
        vehicleId: this.vehicleId,
        expenseId: expenseId
      });
    },
    async addExpense() {
      if (this.tripDetail && this.vehicleDetail) {
        try {
          this.addExpenseDetail.tripId = this.tripDetail.id;
          // dispatch action to add expense via vuex
          await this.$store
            .dispatch("addExpense", {
              vehicleId: this.vehicleDetail.id,
              expenseDetail: this.addExpenseDetail
            })
            .then(async () => {
              // when expense is added check if there are attachements to be added with it
              if (this.expenseAttachments != []) {
                if (this.vehicleDetail && this.expenseDetail)
                  // if attachments are to be added
                  await this.attachExpenses(
                    this.vehicleDetail.id,
                    this.expenseDetail.id
                  );
              }
              notification.success("Expense added");
              // clear addExpenseDetail for next expense addition
              this.addExpenseDetail = {
                expenseType: undefined,
                merchant: undefined,
                price: undefined,
                dateTime: undefined,
                recurring: undefined,
                taxPercent: undefined,
                location: undefined,
                comment: undefined,
                tripId: undefined
              };
              // hide the modal
              this.addExpenseToggle = false;
              if (this.tripDetail && this.vehicleDetail) {
                // fetch expenses again to update the displayed list
                this.fetchExpenses(this.tripDetail.id, this.vehicleDetail.id);
              }
            });
        } catch (err) {
          notification.error(err.data.message);
        }
      }
    },
    async updateExpense() {
      if (
        this.tripDetail &&
        this.expenseDetail &&
        this.updateExpenseDetail &&
        this.vehicleDetail
      ) {
        // add current attachments to send to attach expenses function
        const currentAttachments = this.updateExpenseDetail.attachments;
        if (this.vehicleDetail && this.expenseDetail) {
          // attach expenses handles current attachments with the selected ones
          // so that the updated expense has all the attachments
          await this.attachExpenses(
            this.vehicleDetail.id,
            this.expenseDetail.id,
            currentAttachments
          );
        }
        const request = this.updateExpenseDetail;
        delete request.attachments;
        // update details of expense via vuex
        await this.$store
          .dispatch("updateExpense", {
            vehicleId: this.vehicleId,
            expenseId: this.expenseDetail.id,
            updatedExpense: request
          })
          .then(async () => {
            this.updateExpenseToggle = false;
            if (this.tripDetail && this.vehicleDetail) {
              await this.getTripDetail(
                this.tripDetail.id,
                this.vehicleDetail.id
              );
            }
          });
        // fetch expenses again to render the new updated values
        this.fetchExpenses(this.tripDetail.id, this.vehicleDetail.id);
        // update trip in sideview
      }
    },
    async deleteExpense() {
      if (this.tripDetail && this.expenseDetail && this.vehicleDetail) {
        const vehicleId = this.vehicleId;
        const expenseId = this.expenseDetail.id;
        await this.$store
          .dispatch("deleteExpense", {
            vehicleId: vehicleId,
            expenseId: expenseId
          })
          .then(() => {
            this.deleteExpenseToggle = false;
            if (this.tripDetail && this.vehicleDetail) {
              this.fetchExpenses(this.tripDetail.id, this.vehicleDetail.id);
            }
          });
      }
    },
    openAddExpenseModal() {
      this.addExpenseToggle = true;
      this.expenseAttachments = [];
    },
    openAddJobModal() {
      this.addJobToggle = true;
    },
    openViewExpenseModal(expenseID: string) {
      this.expenseDetail = undefined;
      this.getExpense(expenseID);
      this.viewExpenseToggle = true;
    },
    async openUpdateExpenseModal(expenseID: string) {
      await this.getExpense(expenseID);
      this.updateExpenseDetail = this.expenseDetail;
      if (this.updateExpenseDetail && this.expenseDetail?.dateTime) {
        this.updateExpenseDetail.dateTime = new Date(
          this.expenseDetail.dateTime
        );
      }
      this.expenseAttachments = [];
      this.updateExpenseToggle = true;
    },
    async openDeleteExpenseModal(expenseID: string) {
      await this.getExpense(expenseID);
      this.deleteExpenseToggle = true;
    }
  }
});
