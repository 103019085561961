
import Vue from "vue";
import TripsRightSidebar from "@/components/TripsRightSidebar.vue";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
import find from "lodash/find";
import { Trip, TripPage, Vehicle } from "@/shared/models";

export default Vue.extend({
  name: "Trips",
  components: { TripsRightSidebar, NoTenantSelectedMessage },
  data() {
    return {
      tableProps: {
        data: [] as Trip[],
        loading: false,
        total: 0,
        page: 1,
        perPage: (window.innerHeight / 66).toFixed(0) // Calculating rows per page according to screen height
      },
      filteredVehicles: undefined as undefined | Vehicle[],
      selected: undefined as undefined | Trip,
      filters: {
        dateRangeStart: undefined as undefined | Date,
        dateRangeEnd: undefined as undefined | Date,
        vehicles: [] as string[],
        category: [] as string[],
        typePrivate: false,
        typeBusiness: false,
        typeUnmarked: false,
        vehicleSearchQuery: undefined as undefined | string,
        sort: "-startTime"
      }
    };
  },
  computed: {
    trips(): undefined | TripPage {
      return this.$store.state.trips;
    },
    tripId(): undefined | string | (null | string)[] {
      return this.$route.query.tripId;
    },
    vehicleId(): undefined | string | (null | string)[] {
      return this.$route.query.vehicleId;
    },
    vehicles(): undefined | Vehicle[] {
      return this.$store.state.vehicles;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  created() {
    this.initializeView();
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
    },
    trips: function () {
      if (this.$route.query.tripId && this.trips) {
        const selectedTrip = find(this.trips.items, {
          id: this.$route.query.tripId
        } as any);
        // To do: Use of any to be changed on lodash update
        this.selected = selectedTrip;
      }
    }
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        if (this.$route.query.startDate) {
          this.filters.dateRangeStart = new Date(
            this.$route.query.startDate.toString()
          );
        }
        if (this.$route.query.endDate) {
          this.filters.dateRangeEnd = new Date(
            this.$route.query.endDate.toString()
          );
        }
        if (this.$route.query.sort) {
          this.filters.sort = this.$route.query.sort.toString();
        }
        if (this.$route.query.page) {
          this.tableProps.page = parseInt(this.$route.query.page.toString());
        }

        if (this.$route.query.displayVehicleId) {
          this.filters.vehicles = this.$route.query.displayVehicleId
            .toString()
            .split(",");
        }
        if (this.$route.query.category) {
          this.filters.category = this.$route.query.category
            .toString()
            .split(",");
        }
        this.getTrips();
        this.$store
          .dispatch("getVehicles", { selectedTenant: this.selectedTenant })
          .then(() => {
            this.filteredVehicles = this.vehicles;
          });
      }
    },
    //Handle page-change event
    onPageChange(page: number) {
      this.tableProps.page = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page.toString()
        }
      });
      this.getTrips();
    },
    applyTripTypeFilter(filter: string) {
      if (filter === "Private") {
        this.filters.typePrivate = !this.filters.typePrivate;
      } else if (filter === "Business") {
        this.filters.typeBusiness = !this.filters.typeBusiness;
      } else {
        this.filters.typeUnmarked = !this.filters.typeUnmarked;
      }
      this.filterTrips();
    },
    clearVehicleFilter() {
      this.filters.vehicles = [];
      this.filterTrips();
    },
    clearTripTypeFilter() {
      this.filters.category = [];
      this.filterTrips();
    },
    clearDateRangeFilter() {
      this.filters.dateRangeStart = undefined;
      this.filters.dateRangeEnd = undefined;
      this.filterTrips();
    },
    filterTrips() {
      this.tableProps.page = 1;
      if (this.filters.dateRangeEnd) {
        // Prepare endDate with 23:59 time for request
        this.filters.dateRangeEnd.setHours(23, 59);
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          startDate: this.filters.dateRangeStart?.toISOString(),
          endDate: this.filters.dateRangeEnd?.toISOString(),
          sort: this.filters.sort,
          displayVehicleId: this.filters.vehicles.toString(),
          category: this.filters.category.toString()
        }
      });
      this.getTrips();
    },
    filterVehicles() {
      this.filteredVehicles = this.vehicles;
      if (this.filters.vehicleSearchQuery != "") {
        this.filteredVehicles = this.filteredVehicles?.filter((vehicles) => {
          return (
            vehicles.registrationNo
              ?.toLowerCase()
              .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
            vehicles.nickname
              ?.toLowerCase()
              .includes(this.filters.vehicleSearchQuery!.toLowerCase())
          );
        });
      }
    },
    clearVehicleSearchQuery() {
      this.filters.vehicleSearchQuery = "";
      this.filterVehicles();
    },
    selectTrip() {
      if (this.selected) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            vehicleId: this.selected.vehicle.id,
            tripId: this.selected.id
          }
        });
      }
    },
    removeSelection() {
      this.selected = undefined;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          vehicleId: undefined,
          tripId: undefined
        }
      });
    },
    async getTrips() {
      this.tableProps.loading = true;
      const request = {
        selectedTenant: this.selectedTenant,
        page: this.tableProps.page,
        sort: this.filters.sort,
        startDate: this.filters.dateRangeStart,
        endDate: this.filters.dateRangeEnd,
        showVehiclesId: this.filters.vehicles.toString(),
        category: this.filters.category.toString(),
        limit: this.tableProps.perPage
      };
      await this.$store.dispatch("getTrips", request);
      if (this.trips) {
        this.tableProps.total = this.trips.totalItems;
        this.tableProps.data = this.trips.items;
        this.tableProps.loading = false;
      }
    }
  }
});
